import React from 'react';
import { graphql, navigate } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import {
  Layout,
  BlogSection,
  CtaSection,
  SectionTitle,
  Accordion,
  TypewriterText,
} from 'components';
import PlayIcon from 'images/play-icon.png';

const Title = styled.h1`
  @media screen and (min-width: 640px) {
    font-size: 2.25rem;
  }
  letter-spacing: -1px;
`;

const CtaTitle = styled.span`
  font-size: 16px;
  letter-spacing: 1px;
  color: var(--faded-black);
  .search-for {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.875rem;
  }
`;

const StyledHeroSection = styled.div`
  background-image: linear-gradient(114deg, #bce0f6 10%, #cfeeff 40%);

  @media screen and (min-width: 960px) {
    .uk-container {
      padding-bottom: 0;
    }
  }

  .header-image {
    @media screen and (min-width: 1025px) {
      margin-top: -4rem;
    }
  }

  .uk-form-icon {
    color: var(--black);
    width: 60px;
    top: 8px;
  }

  .uk-form-icon:not(.uk-form-icon-flip) ~ .uk-input {
    padding-left: 50px !important;
  }

  .search-field {
    font-family: 'InterUI Medium', sans-serif;
    font-size: 16px;
    background: var(--light-blue);
    padding: 23px;
    border: 0;
    margin-top: 12px;
    height: 64px;
    padding-left: 50px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  }
`;

const StyledJobSection = styled.div`
  background: linear-gradient(
    81deg,
    var(--main-color-hovered) 29%,
    var(--main-color) 93%
  );
`;

const StyledVideo = styled.div`
  margin-top: 2rem;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.25);
  a {
    width: 100%;
  }

  button {
    position: absolute;
    top: calc(50% - 32px);
    left: calc(50% - 32px);
    width: 64px;
    height: 64px;
    border: 0;
    border-radius: 64px;
    box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
    background-color: #ffffff;

    img {
      margin-left: 4px;
      border-radius: 0;
      width: 17px;
      height: 24px;
    }

    :hover {
      cursor: pointer;
    }
  }
`;
const IndexPage = ({
  data: {
    prismicHomePage: {
      data: {
        headline,
        header_image,
        header_video,
        header_video_image,
        accordion_heading,
        accordion,
        jobs_button_text,
        jobs_heading,
        upload_cv_button,
        upload_cv_heading,
      },
    },
    prismicJobsPage: {
      data: {
        header_image: jobsection_header_image,
        career_portal_url,
        career_portal_apply_url,
      },
    },
  },
}) => (
  <Layout>
    <StyledHeroSection>
      <div className="uk-container uk-padding-large">
        <Title className="uk-width-2-3@l uk-width-1-1">{headline.text}</Title>
        <div
          className="uk-animation-slide-bottom-small uk-grid uk-grid-collapse"
          uk-grid="true"
        >
          <div className="uk-width-expand uk-text-center uk-text-left@m">
            <div className="uk-width-1-1">
              <div className="uk-margin-medium-top">
                <StyledVideo>
                  <div uk-lightbox="true">
                    <a
                      class="uk-inline"
                      href={header_video.url}
                      data-attrs="width: 1280; height: 720;"
                    >
                      <Img
                        fluid={
                          header_video_image.localFile.childImageSharp.fluid
                        }
                      />
                      <button>
                        <img src={PlayIcon} />
                      </button>
                    </a>
                  </div>
                </StyledVideo>
              </div>
            </div>
          </div>
          <div className="uk-width-1-2@m header-image uk-visible@m">
            <Img fluid={header_image.localFile.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </StyledHeroSection>

    <div className="uk-container uk-padding-large uk-animation-slide-bottom-medium">
      <SectionTitle
        bulletColor="blue"
        title={accordion_heading.text}
        size="small"
      />
      <Accordion
        items={accordion.map(({ heading, content, image, lottie_json }) => {
          return {
            heading: heading.text,
            content: content.text,
            image: image.localFile,
            lottie_json,
          };
        })}
      />
    </div>

    {
      <StyledJobSection>
        <BackgroundImage
          fluid={jobsection_header_image.localFile.childImageSharp.fluid}
        >
          <div className="uk-container uk-container-medium uk-padding-large">
            <SectionTitle
              title={jobs_heading.text}
              textColor="secondary"
              button={jobs_button_text}
              to={career_portal_url.url}
              bulletColor="blue"
            />
          </div>
        </BackgroundImage>
      </StyledJobSection>
    }

    <BlogSection />
    <CtaSection
      title={upload_cv_heading.text}
      button_text={upload_cv_button}
      url={career_portal_apply_url.url}
    />
  </Layout>
);

export default IndexPage;

export const query = graphql`
  query {
    prismicHomePage {
      data {
        headline {
          text
        }
        header_image {
          localFile {
            childImageSharp {
              fluid(quality: 80, maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        header_video {
          url
        }
        header_video_image {
          localFile {
            childImageSharp {
              fluid(quality: 80, maxWidth: 1024) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        accordion_heading {
          text
        }
        accordion {
          heading {
            text
          }
          content {
            text
          }
          lottie_json {
            url
          }
          image {
            localFile {
              childImageSharp {
                fluid(quality: 80, maxWidth: 1024) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        jobs_button_text
        jobs_heading {
          text
        }
        upload_cv_button
        upload_cv_heading {
          text
        }
      }
    }
    prismicJobsPage {
      data {
        career_portal_url {
          url
        }
        career_portal_apply_url {
          url
        }
        header_image {
          localFile {
            childImageSharp {
              fluid(quality: 80, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
